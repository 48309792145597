<template>
  <b-overlay :show="loading" rounded="sm" no-fade class="mt-1">
    <b-card>
      <b-row class="mb-1">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">Survey Questions</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <b-form>
        <b-row>
          <b-col cols="12" sm="12" md="12" xl="12">
            <b-form-group label="Question" label-for="question">
              <div class="d-flex">
                <b-form-input required id="question" type="text" v-model="question"></b-form-input>
                <b-button type="button" @click="addQuestion" variant="outline-primary">
                  <i class="fa-solid fa-plus"></i>
                </b-button>
              </div>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <strong>Survey Question List:</strong>
            <hr />
            <ul class="question-list">
              <li v-for="(q, index) in questions" :key="index">
                <span>
                  <strong>{{ index + 1 }} - </strong>{{ q.question }}</span
                >
                <b-button @click="removeQuestion(index)" variant="primary" size="sm"><i class="fa-regular fa-trash-can"></i></b-button>
              </li>
            </ul>
          </b-col>

          <b-col cols="12" class="mt-2">
            <b-button type="button" @click="formSubmitted" variant="primary" class="float-right">
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>
<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../settingsStoreModule';
import vSelect from 'vue-select';
import router from '@/router';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    ToastificationContent,
  },

  setup() {
    const QUESTION_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(QUESTION_APP_STORE_MODULE_NAME)) store.registerModule(QUESTION_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(QUESTION_APP_STORE_MODULE_NAME)) store.unregisterModule(QUESTION_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      questions: [],
      question: '',

      loading: false,
    };
  },

  watch: {},

  mounted() {
    this.getSurvey();
  },

  methods: {
    getSurvey() {
      this.loading = true;

      store
        .dispatch('settings/getSurvey')
        .then((res) => {
          this.questions = res.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          });
        });
    },

    addQuestion() {
      if (this.question) {
        this.questions.push({ question: this.question });
        this.question = '';
      }
    },

    removeQuestion(index) {
      this.questions.splice(index, 1);
    },

    formSubmitted() {
      this.loading = true;
      if (this.questions.length == 0) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            text: 'Please fill in all fields.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        });
        this.loading = false;
        return;
      }

      store
        .dispatch('settings/saveSurvey', { questions: this.questions })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Survey updated Successful',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });

          router.push({ name: 'settings-dashboard' });
        })
        .catch((error) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          });
        });
    },
  },

  created() {},

  computed: {},
};
</script>

<style scoped>
.question-list {
  list-style-type: decimal;
  padding: 0;
}

.question-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  padding: 5px;
  border: 1px solid #ccc;
}

.question-list li:hover {
  background-color: #ffffff50;
  cursor: pointer;
}

.question-list b-button {
  flex-shrink: 0;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
